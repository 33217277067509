ion-content.custom-content {
    --background: none;
    background-size: fit;
    background-position: center;
    position: relative;
    background-image: url('../assets/img/content-traq-bg.png');
}

.image-section {
    background-image: url('../assets/img/banner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px; /* Adjust the height as needed */
    position: relative;
}

.image-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5; /* Adjust the opacity as needed */
}
  
.content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
}

.content-container h6 {
    font-size: 16px;
}

.content-container p {
    font-size: 13px;
    margin-top: 10px;
}

.fade-in-out {
    animation: fade 2.5s infinite;
  }
  
@keyframes fade {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}