.pdf-container {
    overflow-x: auto;
}

@media only screen and (max-width: 768px) {
    .pdf-container {
        max-width: 100%;
    }

    /* Detect landscape mode */
    @media only screen and (orientation: landscape) {
        .pdf-container {
            display: flex;
            justify-content: center;
        }

        .pdf-container .rpv-core__viewer {
            flex: 0 0 auto;
        }
    }
}