.approval-container {
  color: black;
  padding: 0 !important;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.padding-0 {
  padding: 0px !important;
}

.container a {
  text-decoration: none;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.day {
  font-size: 48px;
  font-weight: bold;
}

.month {
  font-size: 12px;
  margin-top: -12px;
  font-weight: bold;
}

.event-details {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}

.time {
  font-size: 15px;
  font-weight: bold;
}

.location, .client {
  font-size: 13px;
  margin-top: 5px;
}

.border-right-5{
  border-right: 5px solid lightgoldenrodyellow;
}