.about-button-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px -4px 6px -3px rgba(0, 0, 0, 0.2);
}

.back-button-container {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px -4px 6px -3px rgba(0, 0, 0, 0.2);
}

.legend-container{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}
