.about-container {
    padding: 16px;
}
  
.about-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 16px;
}

.about-content {
    margin-bottom: 16px;
}