ion-content.custom-content {
    --background: none;
    background-size: fit;
    background-position: center;
    position: relative;
    background-image: url('../assets/img/content-traq-bg.png');
}

ion-textarea.custom-textarea {
    --background: #f8f9fa;
    --color: grey;
    --padding-end: 10px;
    --padding-start: 10px !important;
    --placeholder-color: #9f8585;
    --placeholder-opacity: 0.8;
}

.date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: x-large;
    font-weight: bold;
}

.time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: xx-large;
    font-weight: bold;
}

.job-allo-container{
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    font-size: smaller;
    font-weight: bold;
}

.client-container{
    display: flex;
    align-items: flex-start;
    font-size: smaller;
    font-weight: bold;
}

.time-section{
    display: flex;
    align-items: center;
}

.instruction-container{
    border: 1px solid #e1dada;
    border-radius: 5px;
    width: 100%;
    padding: 15px;
}
  