ion-content.custom-content {
    --background: none;
    background-size: fit;
    background-position: center;
    position: relative;
    background-image: url('../assets/img/content-traq-bg.png');
}

.circular-button {
    width: 60px; /* Adjust the width and height as per your desired size */
    height: 60px;
    border-radius: 50%; /* Creates the circular shape */
  }

.dimmed {
    opacity: 0.5;
    pointer-events: none;
}